import axios from '@/plugins/axios';

class AppService {
  getInfo(data) {
    this.data = data;
    return axios().post('/info', this.data).then((response) => response.data);
  }

  boxFlow(data) {
    this.data = data;
    return axios().post('/reports/boxflow', this.data).then((response) => response.data);
  }

  subscription(data) {
    this.data = data;
    return axios().post('/subscription', this.data).then((response) => response.data);
  }

  cancelSubscription(data = null) {
    this.data = data;
    return axios().delete('/subscription/cancel', null).then((response) => response.data);
  }

  getStatistics(data) {
    this.data = data;
    return axios().post('/reports/statistics', this.data).then((response) => response.data);
  }

  getWallets(data) {
    this.data = data;
    return axios().get('/wallets/w', this.data).then((response) => response.data);
  }

  import(data) {
    this.data = data;
    return axios().post('/wallets/import', this.data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'enctype': 'multipart/form-data',
      },
    }).then((response) => response.data);
  }

  finishImport(data) {
    this.data = data;
    return axios().put('/wallets/import/finish', this.data).then((response) => response.data);
  }

  getWallet(uuid) {
    this.data = uuid;
    return axios().get(`/wallets/info/${uuid}`, this.data).then((response) => response.data);
  }

  getCity(q) {
    this.data = q;
    return axios().post(`/settings/cities`, this.data).then((response) => response.data);
  }

  getUserInfo() {
    this.data = null;
    return axios().get('/users/info', this.data).then((response) => response.data);
  }

  updateUserInfo(data) {
    this.data = data;
    return axios().put('/users/update', this.data).then((response) => response.data);
  }

  updateAvatar(data) {
    this.data = data;
    return axios().put('/users/update-avatar', this.data).then((response) => response.data);
  }

  getProvider(data) {
    this.data = data;
    return axios().post('/providers/get', this.data).then((response) => response.data);
  }

  updateProvider(data) {
    this.data = data;
    return axios().put('/providers', this.data).then((response) => response.data);
  }

  deleteProvider(id) {
    this.data = id;
    return axios().delete(`/providers/${id}`, this.data).then((response) => response.data);
  }

  addProvider(data) {
    this.data = data;
    return axios().post('/providers', this.data).then((response) => response.data);
  }

  addWallet(data) {
    this.data = data;
    return axios().post('/wallets', this.data).then((response) => response.data);
  }

  getNubankToken(data) {
    this.data = data;
    return axios().post('/banks/nubank/token', this.data).then((response) => response.data);
  }

  getNubankCertificate(data) {
    this.data = data;
    return axios().put('/banks/nubank/certificate', this.data).then((response) => response.data);
  }

  getNubankCertificateCard(data) {
    this.data = data;
    return axios().put('/banks/nubank/certificate-card', this.data).then((response) => response.data);
  }

  nubankSync(data) {
    this.data = data;
    return axios().put('/banks/nubank/sync', this.data).then((response) => response.data);
  }

  nubankSyncCard(data) {
    this.data = data;
    return axios().put('/banks/nubank/sync-card', this.data).then((response) => response.data);
  }

  getPdf(data) {
    this.data = data;
    return axios().post('/reports/pdf', this.data).then((response) => response.data);
  }

  updateWallet(data) {
    this.data = data;
    return axios().put('/wallets', this.data).then((response) => response.data);
  }

  deleteAccount(id) {
    this.data = id;
    return axios().delete(`/wallets/${id}`, this.data).then((response) => response.data);
  }

  deleteCard(id) {
    this.data = id;
    return axios().delete(`/cards/${id}`, this.data).then((response) => response.data);
  }

  getCards(data) {
    this.data = data;
    return axios().post('/cards/list', this.data).then((response) => response.data);
  }

  addCard(data) {
    this.data = data;
    return axios().post('/cards', this.data).then((response) => response.data);
  }

  updateCard(data) {
    this.data = data;
    return axios().put('/cards', this.data).then((response) => response.data);
  }

  getInvoices(data) {
    this.data = data;
    return axios().post('/cards/invoices', this.data).then((response) => response.data);
  }
  
  getSubscriptions(data) {
    this.data = data;
    return axios().post('/cards/subscriptions', this.data).then((response) => response.data);
  }

  getInvoice(data) {
    this.data = data;
    return axios().post('/cards/invoice', this.data).then((response) => response.data);
  }

  addTransaction(data) {
    this.data = data;
    return axios().post('/transactions', this.data).then((response) => response.data);
  }

  updateTransaction(data) {
    this.data = data;
    return axios().put('/transactions', this.data).then((response) => response.data);
  }

  addTransfer(data) {
    this.data = data;
    return axios().post('/transactions/transfer', this.data).then((response) => response.data);
  }

  addBudget(data) {
    this.data = data;
    return axios().post('/budgets', this.data).then((response) => response.data);
  }

  updateBudget(data) {
    this.data = data;
    return axios().put('/budgets', this.data).then((response) => response.data);
  }

  deleteBudget(id) {
    this.data = id;
    return axios().delete(`/budgets/${id}`, this.data).then((response) => response.data);
  }

  getBudgets(data) {
    this.data = data;
    return axios().post('/budgets/get', this.data).then((response) => response.data);
  }

  getGoals(data) {
    this.data = data;
    return axios().post('/goals/get', this.data).then((response) => response.data);
  }

  deleteGoal(id) {
    this.data = id;
    return axios().delete(`/goals/${id}`, this.data).then((response) => response.data);
  }

  addGoalTransaction(data) {
    this.data = data;
    return axios().post('/goals/transactions', this.data).then((response) => response.data);
  }

  addGoal(data) {
    this.data = data;
    return axios().post('/goals', this.data).then((response) => response.data);
  }

  updateGoal(data) {
    this.data = data;
    return axios().put('/goals', this.data).then((response) => response.data);
  }

  openFinish(data) {
    this.data = data;
    return axios().put('/goals/finish-open', this.data).then((response) => response.data);
  }

  deleteTransaction(id) {
    this.data = id;
    return axios().delete(`/transactions/${id}`, this.data).then((response) => response.data);
  }

  payTransaction(data) {
    this.data = data;
    return axios().put('/transactions/bills/pay', this.data).then((response) => response.data);
  }

  addCategory(data) {
    this.data = data;
    return axios().post('/categories', this.data).then((response) => response.data);
  }

  updateCategory(data) {
    this.data = data;
    return axios().put('/categories', this.data).then((response) => response.data);
  }

  getCategories(data) {
    console.log(data);
    this.data = data != '' ? `/${data}` : '';
    return axios().get(`/categories${this.data}`, this.data).then((response) => response.data);
  }

  getSubCategories(data) {
    console.log(data);
    this.data = data != '' ? `/${data}/true/true` : '';
    return axios().get(`/categories${this.data}`, this.data).then((response) => response.data);
  }

  getCategoriesHide(data) {
    console.log(data);
    this.data = data != '' ? `/${data}/1` : '';
    return axios().get(`/categories${this.data}`, this.data).then((response) => response.data);
  }

  getCategoriesShow(data) {
    console.log(data);
    this.data = data != '' ? `/${data}/1` : '';
    return axios().get(`/categories${this.data}`, this.data).then((response) => response.data);
  }

  deleteCategory(id) {
    this.data = id;
    return axios().delete(`/categories/${id}`, this.data).then((response) => response.data);
  }

  getBills(data) {
    this.data = data;
    return axios().post('/transactions/bills', this.data).then((response) => response.data);
  }

  getClient(data) {
    this.data = data;
    return axios().post('/clients/get', this.data).then((response) => response.data);
  }

  updateClient(data) {
    this.data = data;
    return axios().put('/clients', this.data).then((response) => response.data);
  }

  deleteClient(id) {
    this.data = id;
    return axios().delete(`/clients/${id}`, this.data).then((response) => response.data);
  }

  addClient(data) {
    this.data = data;
    return axios().post('/clients', this.data).then((response) => response.data);
  }
}

export default new AppService();
